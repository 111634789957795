import React, { useState } from "react";
import { Script, ScriptStrategy } from "gatsby";
import { Layout } from "../../component/layout";
// import { Helmet } from "react-helmet";
//SEO
export const Head = () => {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      {/* <script
        type="text/javascript"
        src="//typesquare.com/3/tsst/script/ja/typesquare.js?633a9bc8875044eda3042038ac1e02e5"
      ></script> */}
      <Script
        id="first-unique-id"
        type="text/javascript"
        async
        strategy={ScriptStrategy.postHydrate}
        src="https://webfont.fontplus.jp/accessor/script/fontplus.js?mngV5R97MNQ%3D&box=znXioXa0A~s%3D&aa=1&ab=2"
      />
      {/* <script
        id="first-unique-id"
        dangerouslySetInnerHTML={{
          __html: `src='https://webfont.fontplus.jp/accessor/script/fontplus.js?mngV5R97MNQ%3D&box=LacmM-oidHI%3D&aa=1&ab=2'`,
        }}
      /> */}
    </>
  );
};

const scripts = {
  fp: `https://webfont.fontplus.jp/accessor/script/fontplus.js`,
};
const Tr01 = () => {
  return (
    <>
      {/* <Helmet>
        <html lang="ja" />
        <title>Aboutページ</title>
        <meta name="description" content="このページはaboutページです。" />
        <script src="https://webfont.fontplus.jp/accessor/script/fontplus.js?mngV5R97MNQ%3D&box=LacmM-oidHI%3D&aa=1&ab=2"></cript>
      </Helmet> */}
      <Layout>
        <h2>contents 01</h2>
        <p>
          川上の方を見ると、車室の中はすっかり明るくなりました。これは三次空間の方から、すうっと流れて来た方を知ってきのどくがってわざと返事をしました。そしてあの姉弟はもうつかれているのを見ましたら、鳥捕りは、すっかりトパーズの正面になり、インデアンはぴたっと立ちどまって、岩から黒い細長いさきのとがったくるみの実だよ。あすこがほんとうの神さまのとこへ行くんだって。またすぐ眼の下のとこをこすりながら、ジョバンニやカムパネルラのようすを見ていたのさ標本にするんですかカムパネルラは、なぜかそう言いながら、自分もだんだん顔いろがかがやいてきました。
        </p>
      </Layout>
      {/* <Script
        strategy="idle"
        src={scripts.fp}
        onLoad={() => {
          console.log(`success loading ${scripts.fp}`);
          // setLoaded(true);
        }}
        onError={() => console.log(`failure loading ${scripts.fp}`)}
      /> */}
    </>
  );
};

export default Tr01;
